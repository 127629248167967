<template>
  <div class="management-container">
    <UserList @select="selectedUserEmail = $event" />
    <div class="separator" />
    <RolesGroupsList
      :roles="availableRoles" :groups="availableGroups"
      :class="{'opacity': !selectedUserEmail}"
      @select="assignRoles" @hover="hoverRight = $event"
    />
    <div class="toggle-container" :class="{'opacity': !selectedUserEmail}">
      <FontAwesomeIcon class="icon" :class="{'on-hover': hoverRight}" :icon="icon.right" />
      <FontAwesomeIcon class="icon" :class="{'on-hover': hoverLeft}" :icon="icon.left" />
    </div>
    <RolesList
      :roles="assignedRoles" :class="{'opacity': !selectedUserEmail}"
      @select="unassignRole" @hover="hoverLeft = $event"
    >
      <template #title>
        <IxRes>administration.roles.title.assigned</IxRes>
      </template>
    </RolesList>
  </div>
</template>

<script>
import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'
import {sortBy, differenceBy, uniq} from 'lodash'

import {notifications} from 'src/js/infrastructure'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons'

import UserList from './widgets/UserList'
import RolesList from './widgets/RolesList'
import RolesGroupsList from 'src/vue/areas/administration/views/components/user-management/widgets/RolesGroupsList'

export default {
  components: {
    RolesGroupsList,
    FontAwesomeIcon,
    RolesList,
    UserList
  },
  data () {
    return {
      selectedUserEmail: null,
      roles: [],
      groups: [],
      assignedRoles: [],
      hoverRight: false,
      hoverLeft: false
    }
  },
  computed: {
    availableRoles () {
      return this.assignedRoles.length
        ? differenceBy(this.roles, this.assignedRoles, 'id')
        : this.roles
    },
    availableGroups () {
      const roles = this.availableRoles.map(role => role.id)
      return this.groups.filter(group => group.roles.some(role => roles.includes(role)))
    },
    icon () {
      return {
        left: faArrowLeft,
        right: faArrowRight
      }
    }
  },
  methods: {
    async assignRoles (roles) {
      if (!this.selectedUserEmail) return

      const assignedRoles = this.assignedRoles.map(role => role.id)
      const rolesToAssign = roles.filter(role => !assignedRoles.includes(role))
      try {
        await smarterPost('/api/v2/admin/roles/user/{email}/assign', rolesToAssign, {
          id: 'admin.roles.assign',
          inputs: {
            email: () => this.selectedUserEmail
          },
          onResult: () => {
            this.assignedRoles = uniq(this.assignedRoles.concat(rolesToAssign.map(role => ({id: role}))))
            notifications.success(this.$i18n.translate('administration.roles.assign.success'))
          }
        })
      } catch (error) {
        notifications.error(this.$i18n.translate('administration.roles.assign.error'))
      }
    },
    async unassignRole (role) {
      try {
        await smarterPost('/api/v2/admin/roles/user/{email}/unassign', [role.id], {
          id: 'admin.roles.unassign',
          inputs: {
            email: () => this.selectedUserEmail
          },
          onResult: () => {
            this.assignedRoles = this.assignedRoles.filter(assigned => assigned.id !== role.id)
            notifications.success(this.$i18n.translate('administration.roles.unassign.success'))
          }
        })
      } catch (error) {
        notifications.error(this.$i18n.translate('administration.roles.unassign.error'))
      }
    }
  },
  watch: {
    selectedUserEmail (value) {
      if (value) {
        smarterGet('/api/v2/admin/roles/user/{email}', {
          id: 'admin.roles.user',
          inputs: {
            email: () => this.selectedUserEmail
          },
          onResult: (assignedRoles) => {
            this.assignedRoles = sortBy(assignedRoles.map(role => ({id: role.roleName})), 'value')
          }
        })
      } else {
        this.assignedRoles = []
      }
    }
  },
  created () {
    smarterGet([
      '/api/v2/admin/roles/all',
      '/api/v2/admin/role-groups'
    ], {
      id: 'admin.roles.load.all',
      onResult: ([roles, groups]) => {
        this.roles  = sortBy(roles.map(role => ({id: role.name})), 'name')
        this.groups = sortBy(groups, 'name')
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.management-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .separator {
    border-left: 1px solid lightgrey;
  }

  .opacity {
    opacity: 30%;
  }

  .toggle-container {
    align-self: center;
    display: flex;
    flex-direction: column;

    .icon {
      font-size: 3em;
      color: lightgrey;
    }
    .on-hover {
      color: darkgrey;
    }
  }
}
</style>
