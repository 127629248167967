<template>
  <div class="list-container">
    <div class="btn-group btn-group-justified">
      <div
        v-for="button in ['role', 'group']" :key="button"
        class="btn-group"
      >
        <ToggleButton
          v-model="selectedButton"
          :value="button"
          required
        >
          <IxRes>administration.roles.button_{{ button }}</IxRes>
        </ToggleButton>
      </div>
    </div>

    <h3>
      <IxRes v-if="isTypeRole">administration.roles.title.available</IxRes>
      <IxRes v-else>administration.groups.title.available</IxRes>
    </h3>
    <Search
      v-model="searchString" :data="searchData"
      :keys="searchKeys"
      @result="searchResults = $event"
    />
    <RecycleScroller
      v-if="isTypeRole"
      v-slot="{item}" :items="filteredRoles"
      class="scroller" :item-size="64"
      key-field="id"
    >
      <div
        :title="description(item)"
        @click="$emit('select', [item.id])"
        @mouseover="$emit('hover', true)"
        @mouseleave="$emit('hover', false)"
      >
        <ListCard>
          <template #title>
            <IxRes>administration.roles.name_{{ item.id.replace('/','_') }}</IxRes>
          </template>
          <template #addon>
            {{item.id}}
          </template>
          <template #subtitle>
            <IxRes>administration.roles.description_{{ item.id.replace('/','_') }}</IxRes>
          </template>
        </ListCard>
      </div>
    </RecycleScroller>

    <RecycleScroller
      v-else
      v-slot="{item}" :items="filteredGroups"
      class="scroller" :item-size="64"
      key-field="id"
    >
      <div
        :title="groupTitle(item)"
        @click="$emit('select', item.roles)"
        @mouseover="$emit('hover', true)"
        @mouseleave="$emit('hover', false)"
      >
        <ListCard>
          <template #title>
            {{ item.name }}
          </template>
          <template #subtitle>
            <IxRes>administration.roleGroup.group.description_{{ item.name }}</IxRes>
          </template>
        </ListCard>
      </div>
    </RecycleScroller>
  </div>
</template>

<script>
import ToggleButton from '@components/ToggleButton'
import Search from '@components/search/Search'
import ListCard from '@components/card/ListCard'

export default {
  components: {
    ToggleButton,
    ListCard,
    Search
  },
  props: {
    roles: {
      type: Array,
      required: true
    },
    groups: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      searchString: '',
      searchKeys: ['id', 'name'],
      searchResults: null,
      selectedButton: 'role',
      showRoleIds: false
    }
  },
  computed: {
    isTypeRole () {
      return this.selectedButton === 'role'
    },
    searchData () {
      return this.isTypeRole
        ? this.roles
        : this.groups
    },
    filteredRoles () {
      return this.searchResults ? this.searchResults : this.roles
    },
    filteredGroups () {
      return this.searchResults ? this.searchResults : this.groups
    }
  },
  methods: {
    groupTitle (item) {
      if (!(item.hasOwnProperty('roles') && item.roles && item.roles.length)) return []

      return item.roles.map(role => this.$i18n.translate(`administration.roles.name_${role}`)).join(',\n')
    },
    description (item) {
      if (!item) return null

      return this.$i18n.translate(`administration.roles.description_${item.id}`)
    }
  },
  watch: {
    selectedButton () {
      this.searchString = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.list-container {
  display: flex;
  flex-direction: column;

  min-height: 0;
  overflow-y: auto;
  width: 30%;

  .scroller {
    height: 100%;
    margin-top: 16px;
  }
}
</style>
