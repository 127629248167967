<template>
  <div class="list-container">
    <Search
      v-model="searchString" :data="users"
      :keys="searchKeys"
      @result="searchResults = $event"
    />
    <RecycleScroller
      v-slot="{item}" :items="filteredUsers"
      class="scroller" :item-size="42"
      key-field="email"
    >
      <div :title="item.email" @click="toggleSelected(item.email)">
        <ListCard :selected="item.email === selected">
          <template #title>
            {{ item.email }}
          </template>
        </ListCard>
      </div>
    </RecycleScroller>
  </div>
</template>

<script>
import {smarterGet} from '@helpers/vuex/data-loading'
import {sortBy} from 'lodash'

import Search from '@components/search/Search'
import ListCard from '@components/card/ListCard'

export default {
  components: {
    ListCard,
    Search
  },
  data () {
    return {
      searchString: '',
      searchKeys: ['email'],
      searchResults: null,
      users: [],
      selected: null
    }
  },
  computed: {
    filteredUsers () {
      return this.searchResults ? this.searchResults : this.users
    }
  },
  methods: {
    toggleSelected (email) {
      this.selected = this.selected === email
        ? null
        : email

      this.$emit('select', this.selected)
    }
  },
  created () {
    smarterGet('/api/v2/admin/users', {
      id: 'admin.users.load',
      onResult: (users) => {
        this.users = sortBy(users.map(user => ({email: user})), 'email')
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.list-container {
  display: flex;
  flex-direction: column;

  min-height: 0;
  overflow-y: auto;
  width: 30%;

  .scroller {
    height: 100%;
    margin-top: 16px;
  }
}
</style>
