<template>
  <AdministrationLayout>
    <template #header>
      <IxRes>administration.roles.title</IxRes>
    </template>
    <UserManagementForm />

    <FrsLoadingIndicator :requests="['admin.users.load', 'admin.roles.load.all', 'admin.roles.user']" />
  </AdministrationLayout>
</template>

<script>
import AdministrationLayout from './components/AdministrationLayout'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import UserManagementForm from 'src/vue/areas/administration/views/components/user-management/UserManagementForm'

export default {
  components: {
    UserManagementForm,
    AdministrationLayout,
    FrsLoadingIndicator
  }
}
</script>

<style lang="scss" scoped>
</style>
