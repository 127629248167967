<template>
  <div class="list-container">
    <h3>
      <slot name="title" />
    </h3>
    <Search
      v-model="searchString" :data="roles"
      :keys="searchKeys"
      @result="searchResults = $event"
    />
    <RecycleScroller
      v-slot="{item}" :items="filteredRoles"
      class="scroller" :item-size="64"
      key-field="id"
    >
      <div
        :title="description(item)"
        @click="$emit('select', item)"
        @mouseover="$emit('hover', true)"
        @mouseleave="$emit('hover', false)"
      >
        <ListCard>
          <template #title>
            <IxRes>administration.roles.name_{{ item.id.replace('/','_') }}</IxRes>
          </template>
          <template #subtitle>
            <IxRes>administration.roles.description_{{ item.id.replace('/','_') }}</IxRes>
          </template>
        </ListCard>
      </div>
    </RecycleScroller>
  </div>
</template>

<script>
import Search from '@components/search/Search'
import ListCard from '@components/card/ListCard'

export default {
  components: {
    ListCard,
    Search
  },
  props: {
    roles: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      searchString: '',
      searchKeys: ['id'],
      searchResults: null
    }
  },
  computed: {
    filteredRoles () {
      return this.searchResults ? this.searchResults : this.roles
    }
  },
  methods: {
    description (item) {
      if (!item) return null

      return this.$i18n.translate(`administration.roles.description_${item.value}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-container {
  display: flex;
  flex-direction: column;

  min-height: 0;
  overflow-y: auto;
  width: 30%;

  .scroller {
    height: 100%;
    margin-top: 16px;
  }
}
</style>
